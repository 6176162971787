import { Keyword } from "@/domain/keyword";
import { Unit } from "@/domain/unit";
import { stringifyFootprint, stringifyUnitType } from "@/domain/units";
import arrowRight from "@/images/arrow-right-v2.svg";
import cross from "@/images/cross.svg";
import locked from "@/images/locked.svg";
import { formatCount, pluralize } from "@/lib/text";
import { useArmyOp } from "@/ui/army-provider/context";
import { Body } from "@/ui/body";
import { Button } from "@/ui/button";
import { useDatabase } from "@/ui/database-provider";
import { KnownErrorMessage } from "@/ui/db-error";
import { ErrorMessage } from "@/ui/error-message";
import { FormDialog } from "@/ui/form-dialog";
import { useDialog } from "@/ui/hooks/use-dialog";
import { Image } from "@/ui/image";
import { LoadingIndicator } from "@/ui/loading-indicator";
import { Paragraphs } from "@/ui/paragraphs";
import {
  button,
  cardHeading,
  inverseTheme,
  primaryTheme,
} from "@/ui/utils.css";
import { DialogStore } from "@ariakit/react";
import { assignInlineVars } from "@vanilla-extract/dynamic";
import { ReactNode } from "react";
import { useParams } from "wouter";
import { numericalStat } from "../../lib/numerical-stat";
import { AbyssalAllegiancesDialog } from "../abyssal-allegiance-dialog";
import { DesktopViewHeader } from "../army-detail-page/desktop-view-header";
import { CursedArtefactsDialog } from "../cursed-artefacts-dialog";
import { RulesetProvider } from "../game-content-provider";
import { IssuesList } from "../issues-list";
import { KeywordsDialog } from "../keywords-dialog";
import { KeywordsHandler } from "../keywords-handler";
import { ListDivider, ListView } from "../list-view";
import { KeywordMiniIcon, UnitMiniIcons } from "../mini-icon";
import { UnitActionsMenu } from "../unit-actions-menu";
import * as css from "./style.css";

function PurchaseKeywordsButton(props: { unit: Unit }) {
  const database = useDatabase();
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && (
        <KeywordsDialog
          store={store}
          unit={props.unit}
          onSubmit={async (keywordRefs) => {
            await database.updateUnit(props.unit.army.id, props.unit.id, {
              keywords: keywordRefs,
            });
          }}
        />
      )}

      <Button onClick={setOpen} style={primaryTheme} className={button}>
        Purchase Keywords
      </Button>
    </>
  );
}

function RemoveKeyword(props: { keyword: Keyword }) {
  const database = useDatabase();
  const { keyword } = props;

  return (
    <button
      type="button"
      onClick={async () => {
        await database.updateUnit(keyword.unit.army.id, keyword.unit.id, {
          keywords: keyword.unit.data.keywords.filter(
            (k) => k.id !== props.keyword.id,
          ),
        });
      }}
    >
      <Image
        src={cross}
        alt="Remove"
        width={40}
        height={40}
        className={css.iconButtonNegativeMargin}
      />
    </button>
  );
}
function RemoveAbyssalAllegiance(props: { unit: Unit; allegianceId: string }) {
  const database = useDatabase();

  return (
    <button
      type="button"
      onClick={async () => {
        await database.updateUnit(props.unit.army.id, props.unit.id, {
          abyssal_allegiances: props.unit.data.abyssal_allegiances?.filter(
            (a) => a.id !== props.allegianceId,
          ),
        });
      }}
    >
      <Image
        src={cross}
        alt="Remove"
        width={40}
        height={40}
        className={css.iconButtonNegativeMargin}
      />
    </button>
  );
}

function RemoveCursedArtefact(props: { unit: Unit; artefactId: string }) {
  const database = useDatabase();

  return (
    <button
      type="button"
      onClick={async () => {
        await database.updateUnit(props.unit.army.id, props.unit.id, {
          cursed_artefacts: props.unit.data.cursed_artefacts?.filter(
            (a) => a.id !== props.artefactId,
          ),
        });
      }}
    >
      <Image
        src={cross}
        alt="Remove"
        width={40}
        height={40}
        className={css.iconButtonNegativeMargin}
      />
    </button>
  );
}

function CursedArtefactsSection(props: { unit: Unit; readOnly?: boolean }) {
  const database = useDatabase();
  const { isOpen, setOpen, store } = useDialog();
  const { unit, readOnly } = props;

  return (
    <>
      {isOpen && (
        <CursedArtefactsDialog
          unit={unit}
          store={store}
          onSubmit={async (artefactId) => {
            await database.updateUnit(props.unit.army.id, props.unit.id, {
              cursed_artefacts: artefactId ? [{ id: artefactId }] : [],
            });
          }}
        />
      )}

      {unit.cursedArtefacts.length === 0 ?
        readOnly ?
          null
        : <Button className={button} style={inverseTheme} onClick={setOpen}>
            <div>Add Cursed Artefact</div>
          </Button>

      : unit.cursedArtefacts.map((artefact) => {
          return (
            <Card
              key={artefact.id}
              icon={
                readOnly ? null : (
                  <RemoveCursedArtefact unit={unit} artefactId={artefact.id} />
                )
              }
              heading={
                <div>
                  <h2 className={cardHeading}>{artefact.name}</h2>
                  <p className={css.abyssalAllegianceSubheading}>
                    Cursed Artefact
                  </p>
                </div>
              }
            >
              <Body
                fontSize="0.875rem"
                html={`<p>This artefact conveys the following keywords onto this unit: ${artefact.keywordsSummary}.</p>`}
              />

              {readOnly ? null : (
                <Button className={css.cursedArtefact} onClick={setOpen}>
                  <div>Change artefact</div>

                  <Image
                    src={arrowRight}
                    width={40}
                    height={40}
                    className={css.iconButtonNegativeMargin}
                  />
                </Button>
              )}
            </Card>
          );
        })
      }
    </>
  );
}

function AbyssalAllegiancesSection(props: { unit: Unit; readOnly?: boolean }) {
  const database = useDatabase();
  const { isOpen, setOpen, store } = useDialog();
  const { unit, readOnly } = props;

  return (
    <>
      {isOpen && (
        <AbyssalAllegiancesDialog
          unit={unit}
          store={store}
          onSubmit={async (abyssalAllegiancesIds) => {
            await database.updateUnit(unit.army.id, unit.id, {
              abyssal_allegiances: abyssalAllegiancesIds.map((id) => ({ id })),
            });
          }}
        />
      )}

      {unit.abyssalAllegiances.length === 0 ?
        readOnly ?
          null
        : <Button className={button} style={inverseTheme} onClick={setOpen}>
            <div>Select Abyssal Allegiance</div>
          </Button>

      : unit.abyssalAllegiances.map((allegiance) => {
          return (
            <Card
              key={allegiance.id}
              icon={
                readOnly ? null : (
                  <RemoveAbyssalAllegiance
                    unit={unit}
                    allegianceId={allegiance.id}
                  />
                )
              }
              heading={
                <div>
                  <h2 className={cardHeading}>{allegiance.name}</h2>
                  <p className={css.abyssalAllegianceSubheading}>
                    Abyssal Allegiance
                  </p>
                </div>
              }
            >
              {allegiance.definition.spells.map((spell) => {
                return (
                  <div className={css.abyssalAllegianceSpell} key={spell.name}>
                    <h3 className={css.abyssalAllegianceSpellHeading}>
                      {spell.name}
                    </h3>
                    <Body
                      fontSize="0.875rem"
                      html={spell.descriptionWithType}
                    />
                  </div>
                );
              })}

              {readOnly ? null : (
                <Button className={css.cursedArtefact} onClick={setOpen}>
                  <div>Change allegiance</div>

                  <Image
                    src={arrowRight}
                    width={40}
                    height={40}
                    className={css.iconButtonNegativeMargin}
                  />
                </Button>
              )}
            </Card>
          );
        })
      }
    </>
  );
}

function Card(props: {
  heading: ReactNode;
  children: ReactNode;
  icon?: ReactNode;
}) {
  return (
    <div className={css.keywordCard}>
      <div className={css.keywordHeader}>
        {props.heading}
        {props.icon}
      </div>

      {props.children}
    </div>
  );
}

function KeywordCard(props: { keyword: Keyword; readOnly?: boolean }) {
  const { keyword, readOnly } = props;

  return (
    <Card
      heading={
        <h2 className={cardHeading}>
          {keyword.name} <KeywordMiniIcon keywordId={keyword.id} />
        </h2>
      }
      icon={readOnly ? null : <KeywordIconButton keyword={keyword} />}
    >
      <Body html={keyword.descriptionWithTraits} fontSize="0.875rem" />

      <p className={css.keywordNote}>{keyword.note}</p>
    </Card>
  );
}

function KeywordIconButton(props: { keyword: Keyword }) {
  const { keyword } = props;

  if (keyword.isPurchasedOrAdded()) {
    return <RemoveKeyword keyword={keyword} />;
  }

  return (
    <Image
      src={locked}
      alt="Cannot be removed"
      width={40}
      height={40}
      className={css.iconButtonNegativeMargin}
      style={{ opacity: 0.3 }}
    />
  );
}

function Stat(props: { name: string; value: string }) {
  return (
    <div>
      <div className={css.statName}>{props.name}</div>
      <div className={css.statValue}>{props.value}</div>
    </div>
  );
}

function UnitCountButtons(props: { unit: Unit }) {
  const database = useDatabase();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0.875rem 0",
        borderTop: "1px solid #ECECEC",
        lineHeight: 1,
      }}
    >
      <div style={{ flex: "1 0" }}>Unit count</div>

      {props.unit.count > 1 && (
        <Button
          onClick={async () => {
            await database.updateUnit(props.unit.army.id, props.unit.id, {
              count: Math.max(1, props.unit.count - 1),
            });
          }}
          style={{
            padding: "0.5rem 0",
            textAlign: "center",
            backgroundColor: "hsl(0 0% 0% / 0.05)",
            borderRadius: "0.5rem",
            lineHeight: 1,
            flex: "0 0 2.5rem",
            opacity: props.unit.count > 1 ? 1 : 0.5,
          }}
        >
          -
        </Button>
      )}
      <span
        style={{
          textAlign: "center",
          flex: "0 0 3rem",
        }}
      >
        {props.unit.count}
      </span>
      <Button
        onClick={async () => {
          await database.updateUnit(props.unit.army.id, props.unit.id, {
            count: props.unit.count + 1,
          });
        }}
        style={{
          padding: "0.5rem 0",
          textAlign: "center",
          backgroundColor: "hsl(0 0% 0% / 0.05)",
          borderRadius: "0.5rem",
          lineHeight: 1,
          flex: "0 0 2.5rem",
        }}
      >
        +
      </Button>
    </div>
  );
}

function TargetNumbersDialog(props: { unit: Unit; store: DialogStore }) {
  const { unit } = props;
  const hasRanged = unit.range > 0;

  return (
    <FormDialog
      heading="Target numbers"
      subheading={`What dice score to you have to get to hit when attacking?${
        hasRanged ? " Scores for shooting attacks shown in parentheses." : ""
      }`}
      submit="Done"
      store={props.store}
    >
      <div className={css.targetsContainer}>
        {props.unit.targetScores.map((targetScore) => {
          const minScore =
            hasRanged ?
              `${targetScore.meleeMinScore}+ (${targetScore.rangedMinScore}+)`
            : `${targetScore.meleeMinScore}+`;

          return (
            <div key={targetScore.vsType} className={css.targetNumberRow}>
              <div>{stringifyUnitType(targetScore.vsType)}</div>

              <div className={css.targetNumberScore}>{minScore}</div>
            </div>
          );
        })}
      </div>
    </FormDialog>
  );
}

function TargetNumbersButton(props: { unit: Unit }) {
  const { isOpen, setOpen, store } = useDialog();

  return (
    <>
      {isOpen && <TargetNumbersDialog store={store} unit={props.unit} />}

      <Button onClick={setOpen} className={css.targetsButton}>
        <div>Look up target numbers</div>

        <Image
          src={arrowRight}
          width={40}
          height={40}
          className={css.iconButtonNegativeMargin}
        />
      </Button>
    </>
  );
}

function UnitValidationButton(props: { unit: Unit }) {
  return (
    <IssuesList
      issues={props.unit.issues.map((issue) => {
        switch (issue.type) {
          case "DUPLICATE_NON_NUMERICAL_KEYWORD": {
            const usage =
              issue.count === 2 ?
                "twice"
              : pluralize(issue.count, "time", "times", {
                  stylistic: true,
                });
            return `A unit may not contain duplicates of non-numerical keywords, but the ${issue.keyword.name} keyword is present ${usage}.`;
          }

          case "TRAIT_COUNT_MAX_EXCEEDED": {
            const max = pluralize(issue.max, "keyword", "keywords", {
              stylistic: true,
            });
            const count = pluralize(issue.count, "is", "are", {
              stylistic: true,
            });
            return `A unit may not purchase more than ${max} with the ${issue.trait} trait, but ${count} present.`;
          }

          case "ABYSSAL_ALLEGIANCES_NO_WIZARD": {
            return `A unit without the Wizard keyword must not have Abyssal Allegiances.`;
          }

          case "CURSED_ARTEFACTS_NO_GENERAL": {
            return `A unit without the General keyword must not have Cursed Artefacts.`;
          }

          case "ABYSSAL_ALLEGIANCES_COUNT_OUT_OF_BOUNDS": {
            const { count, min, max } = issue;

            if (count === 0 && min === 1 && max === 1) {
              return `A unit with the Wizard keyword must select an Abyssal Allegiance.`;
            }

            const extra = count === 0 ? "" : `, but got ${formatCount(count)}`;
            return `A unit with the Wizard and Tome of Magic keywords must select ${min}–${max} Abyssal Allegiances${extra}.`;
          }

          case "KEYWORD_CONSTRAINTS_FAILED": {
            return issue.message;
          }
        }
      })}
    />
  );
}

export function UnitDetailLayout(props: {
  unit: Unit;
  headerLabel: string;
  readOnly?: boolean;
}) {
  const { unit, readOnly = false } = props;

  const meleeStrikes = numericalStat(unit.meleeStrikes);
  const rangedStrikes = numericalStat(unit.rangedStrikes);
  const strikes =
    unit.range > 0 ? `${meleeStrikes} (${rangedStrikes})` : meleeStrikes;

  return (
    <div className={css.view}>
      <div className={css.cover}>
        <DesktopViewHeader
          label={props.headerLabel}
          menu={readOnly ? null : <UnitActionsMenu />}
        />

        <div className={css.header}>
          <div>
            <h1 className={css.heading}>
              {unit.name}

              {unit.count > 1 && (
                <span style={{ opacity: 0.5 }}> (x{unit.count}) </span>
              )}

              <UnitMiniIcons keywords={unit.keywords} />
            </h1>

            {unit.hasName && (
              <p className={css.unitType}>{stringifyUnitType(unit.type)}</p>
            )}
          </div>

          <div className={css.points}>
            <div
              className={css.pointsValue}
              style={assignInlineVars({
                [css.pointsValueFontSize]:
                  unit.points >= 1000 ? "1.25rem" : "1.5rem",
              })}
            >
              {unit.points}
            </div>
            <div className={css.pointsLabel}>Points</div>
          </div>
        </div>

        <div className={css.stats}>
          <Stat name="Speed" value={numericalStat(unit.speed)} />
          <Stat name="Range" value={numericalStat(unit.range, "″")} />
          <Stat name="Strikes" value={strikes} />
          <Stat name="Courage" value={numericalStat(unit.courage)} />
          <Stat name="Footprint" value={stringifyFootprint(unit.footprint)} />
          <Stat name="Multiplier" value={`${unit.keywordCostMultiplier}x`} />
        </div>

        {!readOnly && !unit.hasReservedKeyword() && (
          <UnitCountButtons unit={unit} />
        )}

        <TargetNumbersButton unit={unit} />

        {!unit.isValid() && <UnitValidationButton unit={unit} />}

        {unit.description && (
          <div className={css.description}>
            <Paragraphs text={unit.description} />
          </div>
        )}
      </div>

      <ListView
        items={
          <>
            {(unit.hasKeyword("general") ||
              unit.cursedArtefacts.length > 0) && (
              <CursedArtefactsSection unit={unit} readOnly={readOnly} />
            )}

            {(unit.hasKeyword("wizard") ||
              unit.abyssalAllegiances.length > 0) && (
              <AbyssalAllegiancesSection unit={unit} readOnly={readOnly} />
            )}

            <ListDivider />

            {unit.keywords.map((keyword) => {
              return (
                <KeywordCard
                  key={keyword.id}
                  keyword={keyword}
                  readOnly={readOnly}
                />
              );
            })}
          </>
        }
        stickyButton={readOnly ? null : <PurchaseKeywordsButton unit={unit} />}
      />
    </div>
  );
}

export function UnitDetailView(props: {
  headerLabel: string;
  readOnly?: boolean;
}) {
  const params = useParams<{ unitId: string; armyId: string }>();
  const armyOp = useArmyOp();

  if (armyOp.isPending) {
    return <LoadingIndicator />;
  }

  if (armyOp.isFailure) {
    return (
      <KnownErrorMessage
        error={
          armyOp.failure.type === "ARMY_NOT_FOUND" ?
            { ...armyOp.failure, type: "ARMY_UNIT_NOT_FOUND" }
          : armyOp.failure
        }
      />
    );
  }

  const army = armyOp.value;
  const unit = army.getUnit(params.unitId);

  if (!unit) {
    return <ErrorMessage message="Unit not found. Perhaps it was deleted?" />;
  }

  return (
    <RulesetProvider ruleset={army.ruleset}>
      <KeywordsHandler>
        <UnitDetailLayout {...props} unit={unit} />
      </KeywordsHandler>
    </RulesetProvider>
  );
}
