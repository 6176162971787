import { ReactNode } from "react";
import menuUrl from "../../images/menu.svg";
import { Link } from "../link";
import { useNavControls } from "./context";
import * as css from "./style.css";

function OpenSidebarButton() {
  const { toggleOpen } = useNavControls();

  return (
    <button className={css.navButton} type="button" onClick={toggleOpen}>
      <img src={menuUrl} alt="Menu" />
    </button>
  );
}

function BackButton(props: { href: string }) {
  return (
    <Link href={props.href} className={css.navButton}>
      <svg
        style={{ transform: "scale(0.8) translateX(-15%)" }}
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.24537e-07 12L20.5714 24L11.7195 12L20.5714 -8.99206e-07L5.24537e-07 12Z"
          fill="white"
        />
      </svg>
    </Link>
  );
}

export type MobileNavProps = {
  backHref?: string;
  title: ReactNode;
  action?: ReactNode;
  withShadow?: boolean;
};

export function MobileNav({ withShadow, ...props }: MobileNavProps) {
  return (
    <div className={css.headerNav({ withShadow })}>
      <div>
        {props.backHref ?
          <BackButton href={props.backHref} />
        : <OpenSidebarButton />}
      </div>

      <div className={css.headerTitle}>{props.title}</div>

      <div className={css.action}>{props.action}</div>
    </div>
  );
}

export function Spine(props: { isRoot: boolean }) {
  const { toggleOpen } = useNavControls();

  return (
    <div className={css.spine({ withDivider: props.isRoot })}>
      {props.isRoot ?
        <span className={css.logoArea}>
          <img src="/logos/logo-small.png" alt="" className={css.logo} />
        </span>
      : <button className={css.navButton} type="button" onClick={toggleOpen}>
          <img src={menuUrl} alt="Menu" />
        </button>
      }

      <div className={css.text}>
        <div className={css.gameTitle}>Mike Hutchinson’s Hobgoblin</div>
        <div className={css.gameSubtitle}>Brutal Fantasy Battles</div>
      </div>

      <div />
    </div>
  );
}
