import { ReactElement } from "react";
import { ExternalLink } from "../external-link";
import { Link } from "../link";
import { LoadingIndicator } from "../loading-indicator";
import { MobileNavLayout } from "../mobile-nav-layout";
import { useSession } from "../session-provider/context";
import { getLoginUrl } from "../session-provider/ory";
import {
  button,
  interactiveTextWhite,
  primaryTheme,
  yellowTheme,
} from "../utils.css";
import { page } from "./content";
import electiLogoUrl from "./images/electi-studio.png";
import itcLogoUrl from "./images/itc-white.svg";
import * as css from "./style.css";

function PageHeadingGroup() {
  return (
    <hgroup
      className={css.titleGroup}
      role="group"
      aria-roledescription="heading group"
    >
      <h1 className={css.kicker}>{page.title}</h1>
      <p className={css.title} aria-roledescription="subtitle">
        {page.subtitle}
      </p>
    </hgroup>
  );
}

function Footer() {
  return (
    <footer className={css.footer}>
      <div className={css.footerContent}>
        <h2 className={css.footerHeading}>Credits</h2>
        <div className={css.footerText}>
          <p>Author and Game Designer: Mike Hutchinson</p>
          <p>Editors: David Hoobyar, Stuart Warren</p>
          <p>Graphic Design and Layout: Gregory Horton</p>
          <p>
            Illustration: CROM, Sean Sutter, The Forge Studios, Domenico Cava
          </p>
          <p>App Design and Development: Indie Tabletop Club, Noami Studio</p>
        </div>

        <div className={css.footerLogos}>
          <img
            className={css.footerLogo}
            src={electiLogoUrl}
            alt="Electi Studio"
          />
          <img
            className={css.footerLogo}
            src={itcLogoUrl}
            alt="Indie Tabletop Club"
          />
        </div>
      </div>
    </footer>
  );
}

function CtaSection() {
  return (
    <section className={css.ctaSection}>
      <img src={page.ctaImageUrl} alt="" className={css.ctaImage} />
      <div className={css.ctaHeader}>
        <p className={css.ctaKicker}>{page.ctaKicker}</p>
        <h2 className={css.ctaHeading}>{page.ctaHeading}</h2>
      </div>
      <p className={css.ctaDescription}>{page.ctaDescription}</p>

      <div className={css.ctas}>
        <ExternalLink
          href="https://www.electi-studio.com/store/p/hobgoblin-pdf-only?utm_source=indietabletopclub&utm_medium=hobgoblinbattlegen"
          className={css.ctaButton}
          style={primaryTheme}
        >
          {page.ctaButtonDigitalLabel}
        </ExternalLink>

        {/* Hardcover currently not listed on Electi website. */}
        {/* <ExternalLink
          href="https://www.electi-studio.com/store/p/hobgoblin-standard-harcover?utm_source=indietabletopclub&utm_medium=hobgoblinbattlegen"
          className={css.ctaButton}
          style={primaryTheme}
        >
          {page.ctaButtonPhysicalLabel}
        </ExternalLink> */}
      </div>
    </section>
  );
}

function SignInPrompt() {
  return (
    <>
      <div className={css.promptFade} />
      <div className={css.promptArea}>
        <div className={css.promptBox}>
          <h2 className={css.promptHeading}>Log in to read</h2>
          <p className={css.promptDescription}>
            A <em>free</em> Indie Tabletop Club account is needed to access the
            full quick start rules.
          </p>

          <div className={css.promptButtons}>
            <a className={button} style={yellowTheme} href="/join">
              Join
            </a>

            <p>
              Already have an account?{" "}
              <a className={interactiveTextWhite} href={getLoginUrl()}>
                Log in
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export function QuickStartPage() {
  const { isRetrievingSession, isLoggedIn } = useSession();

  const content =
    isRetrievingSession || !isLoggedIn ?
      page.content.slice(0, 5)
    : page.content;

  return (
    <MobileNavLayout title="Rules">
      <div className={css.pageContent}>
        <img
          className={css.columnCoverImage}
          src={page.mainImageUrl}
          alt=""
          width={1300}
          height={1520}
        />

        <div className={css.content}>
          <PageHeadingGroup />

          <img
            className={css.contentCoverImage}
            src={page.contentCoverImageUrl}
            alt=""
          />

          <main className={css.main}>
            {content.map((element): ReactElement => {
              switch (element.type) {
                case "heading": {
                  const { level } = element.attrs;
                  const Tag = `h${level}` as const;
                  return (
                    <Tag
                      className={
                        level === 2 ? css.headingLevel2
                        : level === 3 ?
                          css.headingLevel3
                        : undefined
                      }
                    >
                      {element.content[0].text}
                    </Tag>
                  );
                }

                case "paragraph": {
                  return (
                    <p className={css.paragraph}>{element.content[0].text}</p>
                  );
                }

                case "image": {
                  return (
                    <img className={css.image} src={element.attrs.src} alt="" />
                  );
                }

                case "unorderedList":
                case "orderedList": {
                  const Tag =
                    element.type === "orderedList" ?
                      ("ol" as const)
                    : ("ul" as const);
                  return (
                    <Tag className={css[element.type]}>
                      {element.content.map((listEl) => {
                        return (
                          <li className={css.listItem}>
                            <p>{listEl.content[0].content[0].text}</p>
                          </li>
                        );
                      })}
                    </Tag>
                  );
                }

                case "button": {
                  return (
                    <Link
                      href={element.attrs.path}
                      className={css.contentButton}
                    >
                      {element.text}

                      <svg
                        className={css.contentButtonIcon}
                        width="40px"
                        height="40px"
                        viewBox="0 0 40 40"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.3506955,29.1086932 C20.4197309,26.0342966 24.3028324,23.2029709 27,20.614716 C23.8750878,17.9178812 19.5024121,14.883117 13.8819729,11.5104234 L21.8305566,20.614716 L15.3506955,29.1086932 Z"
                          transform="translate(20.441, 20.3096) scale(1, -1) translate(-20.441, -20.3096)"
                          fill="#232424"
                        />
                      </svg>
                    </Link>
                  );
                }
              }
            })}

            {isLoggedIn && <CtaSection />}
          </main>

          {isRetrievingSession ?
            <div className={css.pendingState}>
              <LoadingIndicator />
            </div>
          : !isLoggedIn ?
            <SignInPrompt />
          : <Footer />}
        </div>
      </div>
    </MobileNavLayout>
  );
}
