import { ExternalLink } from "../external-link";
import * as css from "./style.css";

export function PreorderButton() {
  return (
    <ExternalLink
      href="https://www.electi-studio.com/store/p/hobgoblin-pdf-only?utm_source=indietabletopclub&utm_medium=hobgoblinbattlegen"
      className={css.cta}
    >
      Get the Rules
    </ExternalLink>
  );
}
