import { Footer } from "@/ui/footer";
import { Link } from "@/ui/link";
import { PreorderButton } from "@/ui/preorder-button";
import { useDocumentColor } from "../hooks/use-document-color";
import { MobileNavLayout } from "../mobile-nav-layout";
import { Picture, PictureProps } from "../picture";
import { useSession } from "../session-provider/context";
import { color } from "../tokens";
import armyBuilderWide from "./army_builder_default.webp";
import armyBuilderTall from "./army_builder_tall.webp";
import chaosOfWarWide from "./chaos_of_war_default.webp";
import chaosOfWarTall from "./chaos_of_war_tall.webp";
import rulesWide from "./rules_default.webp";
import rulesTall from "./rules_tall.webp";
import * as css from "./style.css";

function LinkCard(props: {
  picture?: PictureProps;
  heading: string;
  description: string;
  href: string;
}) {
  return (
    <Link href={props.href} className={css.toolLink}>
      {props.picture && (
        <Picture className={css.toolImage} {...props.picture} />
      )}

      <div className={css.toolContent}>
        <h2 className={css.toolHeading}>{props.heading}</h2>
        <p className={css.toolDescription}>{props.description}</p>
      </div>
    </Link>
  );
}

export function DashboardPage() {
  useDocumentColor(color.CHARCOAL_BLUE);
  const { isLoggedIn } = useSession();

  return (
    <MobileNavLayout title="Home" withShadow>
      <div className={css.page}>
        <div className={css.container}>
          <main className={css.links}>
            <LinkCard
              picture={{
                media: [
                  {
                    media: css.Layout.TWO_COLUMNS,
                    srcSet: armyBuilderTall,
                    width: 640,
                    height: 800,
                  },
                ],
                default: { src: armyBuilderWide, width: 640, height: 350 },
              }}
              heading="Army Builder"
              description="Build, manage, and share your Hobgoblin armies!"
              href="/army"
            />
            <LinkCard
              picture={{
                media: [
                  {
                    media: css.Layout.TWO_COLUMNS,
                    srcSet: chaosOfWarTall,
                    width: 640,
                    height: 800,
                  },
                ],
                default: { src: chaosOfWarWide, width: 640, height: 350 },
              }}
              heading="Chaos of War"
              description="Generate scenario, battlefield, deployment, and twist with one click!"
              href="/battle"
            />
            <LinkCard
              picture={{
                media: [
                  {
                    media: css.Layout.TWO_COLUMNS,
                    srcSet: rulesTall,
                    width: 640,
                    height: 800,
                  },
                ],
                default: { src: rulesWide, width: 640, height: 350 },
              }}
              heading="Quick Start Rules"
              description="Learn the basics of Hobgoblin right within the app."
              href="/quick-start"
            />
            <LinkCard
              heading="Fortune Cards"
              description="Use a regular deck of playing cards as your Fortune deck."
              href="/fortune"
            />
            <LinkCard
              heading="Settings"
              description="Configure the app to your likeing."
              href="/settings"
            />
            <LinkCard
              heading="Account"
              description="Review your account info and sync log."
              href="/account"
            />
            <LinkCard
              heading="Help"
              description="Read about how to use the app, install, print, and more."
              href="/help"
            />
            <LinkCard
              heading="Changelog"
              description="Learn about recent changes to the app."
              href="/changelog"
            />
          </main>
          <div className={css.buttons}>
            {!isLoggedIn && (
              <Link href="/account" className={css.cta}>
                Join / Log in
              </Link>
            )}

            <PreorderButton />
          </div>
        </div>

        <footer className={css.footer}>
          <Footer />
        </footer>
      </div>
    </MobileNavLayout>
  );
}
